import logo from '../../photos/logo.png';

import './Navigation.css';

const Navigation = () => {
  return (
    <nav className="navbar navbar-light p-3 bg-light">
      <a href="https://www.predental.ro" target="__blank">
        <img className="logoNav" src={logo} alt="Predental Logo" />
      </a>
    </nav>
  );
};

export default Navigation;
