import React, { useState, useEffect } from 'react';

import Lottie from 'lottie-react';
// import mainError from './animations/mainError.json';
import loadingDots from './animations/loadingDots.json';

import clinicaPredental from './photos/clinicaPredental.jpg';

import { BiPhone } from 'react-icons/bi';
import { FiClock, FiMail } from 'react-icons/fi';

import 'bootstrap/dist/css/bootstrap.css';
import './App.css';

// import services1 from './services.json';
// import staff from './staff.json';

import Header from './components/Header/Header';
import Navigation from './components/Navbar/Navigation';
import Main from './components/Main/Main';

function App() {
  const [loading, setLoading] = useState(true);
  const [services, setServices] = useState(null);

  const [afterBooking, setAfterBooking] = useState(false);
  const [staffs, setStaffs] = useState(null);

  const [error, setError] = useState(false);
  const [idForAds, setIdForAds] = useState('');
  const [dataAfter, setDataAfter] = useState(null);
  const [showFooter, setShowFooter] = useState(true);

  const changeAfterBooking = (selectedData, email, name) => {
    setDataAfter({ selected: selectedData, email, name });
    setAfterBooking((prevState) => !prevState);
  };

  useEffect(() => {
    const queryString = window.location.search;

    const urlParams = new URLSearchParams(queryString);

    const adsID = urlParams.get('glcid');

    if (adsID) {
      setIdForAds(adsID);
    }

    const getData = async () => {
      try {
        const data = await fetch(
          '/server/production_bookings_project_function/services'
        );
        const response = await data.json();

        if (response.data.response.status === 'Error') {
          setError(true);
          setLoading(false);
        } else {
          setIdForAds(adsID);
          setServices(response.data);
          setStaffs(response.staffs);
          setLoading(false);
        }
      } catch (e) {
        //console.log('Err:', e);
        setError(true);
        setLoading(false);
      }
    };
    getData();
  }, []);

  if (loading) {
    return <Lottie style={{ height: '300px' }} animationData={loadingDots} />;
  }

  if (error) {
    return window.open(
      'https://predental.zohobookings.eu/#/customer/predentalsmile',
      '_self'
    );
  }

  // if (error) {
  //   return (
  //     <div
  //       style={{ height: '800px' }}
  //       className="d-flex flex-column justify-content-center align-items-center"
  //     >
  //       <h4>A aparut o problema, se lucreaza...</h4>
  //     </div>
  //   );
  // }

  return (
    <>
      <Navigation />
      <div className="container">
        {!afterBooking ? (
          <>
            <Header />
            <Main
              data={services.response.returnvalue.data}
              staffs={staffs.response.returnvalue.data}
              adsID={idForAds}
              bookDone={changeAfterBooking}
            />
          </>
        ) : (
          <div className="feedBack shadow-lg p-5 mb-5 bg-body rounded">
            <i
              className="fas fa-check-circle mb-1"
              style={{ fontSize: '2rem', color: '#198754' }}
            ></i>
            <div className="mb-3">Programarea s-a realizat cu succes! </div>

            <ul className="list-group">
              <li className="list-group-item">
                <i
                  className="far fa-calendar-alt me-2"
                  style={{ display: '' }}
                ></i>{' '}
                {dataAfter.selected.date}
                <br />
                <i className="fas fa-user-circle"></i>{' '}
                {dataAfter.selected.staff.name}
              </li>
            </ul>
            <div className="form-text mt-2">
              Toate detaliile au fost trimise pe adresa: {dataAfter.email}{' '}
              <br></br>Multumim, {dataAfter.name}!
            </div>
            <button
              type="button"
              onClick={() => window.location.reload()}
              className="mt-3 btn btn-primary btn-sm"
            >
              <i className="fas fa-redo-alt text-white me-2"></i> Inapoi la
              pagina principala
            </button>
          </div>
        )}
      </div>

      {showFooter && (
        <>
          <div
            className="container"
            style={{
              marginTop: '100px',
              fontSize: '14px',
              paddingBottom: '20px',
            }}
          >
            <div className="row">
              <div className="col-xs-1 col-md-6">
                <img
                  src={clinicaPredental}
                  alt="Clinica Predental"
                  className="img-fluid"
                />
              </div>
              <div className="col-xs-1 col-md-6 mt-3">
                <p>
                  Clinica noastră este situată chiar lângă piața Drumul Taberei,
                  în inima sectorului 6, București.
                </p>
                <p>
                  Adresa:{' '}
                  <span style={{ color: '#3004EA', fontWeight: 'bold' }}>
                    București, Sectorul 6, Str. Brașov, nr.19, bloc OD5, scara
                    1, parter.
                  </span>
                </p>{' '}
                <p className="mb-4">
                  Intrarea se face prin fața blocului, latura dinspre piața
                  Drumul Taberei. Pentru a ajunge la noi, puteți folosi
                  tramvaiul 41 sau mijloacele de transport în comun 168, 105 sau
                  368, stația "Piața Drumul Taberei". De asemenea, se poate
                  ajunge folosind metroul M5, coborând la stația "Parc Drumul
                  Taberei".
                </p>{' '}
                <p className="mb-4">
                  {' '}
                  Pentru a ajunge cu mașina, ne puteți căuta pe Waze -
                  "Predental".
                </p>{' '}
                <p className="mb-4">
                  Vă recomandăm cu căldură sa ne vizitați doar cu o programare
                  în prealabil. Aceasta se poate face fie telefonic la numărul
                  de telefon de mai jos, fie folosind butonul de mai sus.
                </p>
              </div>
            </div>

            <iframe
              title="Predental"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2849.457931514858!2d26.034723999999997!3d44.423767999999995!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40b201b676c98363%3A0x54318051ccd73993!2sPredental%20-%20Clinic%C4%83%20dentar%C4%83!5e0!3m2!1sro!2sro!4v1665728053290!5m2!1sro!2sro"
              width="100%"
              height="450"
              className="mt-5"
              style={{ border: 0 }}
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>

          <div
            className="container text-center mt-4"
            style={{ paddingBottom: '100px', fontSize: '20px' }}
          >
            <div className="row justify-content-center">
              <div className="col-md-3 col-xs-1 mb-3">
                <a
                  style={{ textDecoration: 'none', color: 'black' }}
                  href="tel:0799 994 904"
                >
                  <BiPhone
                    style={{
                      color: '#0057bb',
                      textAlign: 'center',
                      fontSize: '40px',
                    }}
                  />
                  <br />
                  0799 994 904
                </a>
              </div>
              <div className="col-md-3 col-xs-1 mb-3">
                <FiClock
                  style={{
                    color: '#0057bb',
                    textAlign: 'center',
                    fontSize: '40px',
                  }}
                />
                <br />
                Luni - Vineri: 10:00 – 20:00
                <br /> Sâmbătă - Duminică: Inchis
              </div>
              <div className="col-md-3 col-xs-1 mb-3">
                <a
                  style={{ textDecoration: 'none', color: 'black' }}
                  href="mailto:smile@predental.ro"
                >
                  <FiMail
                    style={{
                      color: '#0057bb',
                      textAlign: 'center',
                      fontSize: '40px',
                    }}
                  />
                  <br />
                  smile@predental.ro
                </a>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default App;
